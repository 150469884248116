<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Left Text-->
            <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
                >
                    <b-img
                        fluid
                        :src="'/illustrations/otp.svg'"
                        alt="Register V2"
                        style="width: 75%;"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Enter your OTP
                    </b-card-title>
                    <p>
                        A five digit code has been sent to your email and it will expire in 5 minutes. You can request new code by clicking <strong>Resend OTP</strong> if the code is expired.
                    </p>

                    <!-- form -->
                    <b-form class="auth-register-form mt-2">
                        <!-- username -->
                        <b-form-group label-for="otp" class="d-flex justify-content-center">
                            <otp-input
                                ref="otpInput"
                                input-classes="otp-input"
                                :num-inputs="5"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                separator="-"
                                @on-complete="(value) => (otp = value)"
                            />
                            <!-- @on-complete="validationForm" -->
                            <div v-if="resentMessage" class="alert alert-success p-1 mt-1">We have just sent you a new OTP to your email.</div>
                        </b-form-group>
                        <div>
                            <div class="d-flex justify-content-end">
                                <b-link class=" pb-1" style="text-decoration: underline" @click="resendOtp">Resend OTP</b-link>
                            </div>

                            <b-button
                                block
                                variant="primary"
                                :disabled="loading"
                                @click="verify"
                            >
                                <span
                                    v-if="loading"
                                    class="
                                        d-flex
                                        justify-content-center
                                        next-button
                                    "
                                >
                                    Verifying
                                    <normal-loader class="ml-.5" />
                                </span>

                                <span v-else>Verify</span>
                            </b-button>
                        </div>
                    </b-form>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import OtpInput from "@bachdgvn/vue-otp-input";
import {
    BRow,
    BCol,
    BLink,
    BForm,
    BFormGroup,
    BImg,
    BCardTitle,
    BButton,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { generateOtp, verifyOtp } from "@/apis/auth";
import { errorToast } from "@/views/helpers/toast";

export default {
    components: {
        BRow,
        BImg,
        BCol,
        BLink,
        BForm,
        BCardTitle,
        BFormGroup,
        OtpInput,
        BButton,
        // validations
    },
    data() {
        return {
            status: false,
            otp: "",
            // validation
            loading: false,
            resentMessage: false
        };
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
        }),
    },

    methods: {
        async verify() {
            this.loading = true;
            await verifyOtp(this.otp)
                .then(async response => {
                    if (response && response.status === 200) {
                        this.$store.commit('auth/UPDATE_VERIFIED_STATUS', true);
                        this.$router.push("/");
                    }
                })
                .catch(e => {
                    if (e.response.status === 401) {
                        errorToast(e.response.data.message);
                    }
                });

            this.loading = false;
        },

        async resendOtp() {
            this.resentMessage = true;
            const response = await generateOtp();
            if (response.status !== 200) {
                errorToast("Something went wrong! Please try again after some minutes");
            }

        },
    },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.otp-input {
    width: 45px;
    height: 45px;
    padding: 5px;
    margin-left: 7px;
    margin-right: 7px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
        border: 1px solid red !important;
    }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0 !important;
}
.next-button .preloader {
    left: 50%;
}
</style>
